<template>
<v-app>
  <div>
    <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
    </v-alert>
  </div>
  <div class="row">
    <div class="col-md-6 ml-auto mr-auto">
      <div class="mb-8 mt-8 text-center">
          <h1>Timesheet Approvals</h1>
      </div>
      <v-form ref="salarycomponent" v-model="valid">
        <v-row no-gutters>
          <!-- <v-col>
            <SelectField :items="salaryCycles" v-model="formData.salaryCycle" :label="'Salary Cycle'" :itemText="'description'" :itemValue="'id'" :returnObject="true" @input="selectedCycle" />
          </v-col> -->
           <!-- <v-col>
            <SelectField :items="rotations" v-model="formData.rotation" :label="'Rotation'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="selectedRotation" />
          </v-col> -->
           <v-col>
            <SelectField :items="timeSheetActions" v-model="formData.action" :label="'Action'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="selectedRotation" />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>

    <!-- timesheet calendar  -->

  <div class="scroll row">
    <div class="col-md-10 col-sm-12 ml-auto mr-auto">
      <div class="row">
        <div class="col-md-6">
          <v-text-field
            v-model="searchTimesheet"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </div>
        <div class="col-md-6 d-flex justify-end">
          <span v-if="timeSheetSummary.length > 0">
            <Button  :disabled="!valid" :label="'Submit'" :btnType="'Submit'" @onClick="openConfirm" :isLoading="isProcessing" class="mr-4" />
          </span>
        </div>
      </div>
      <div v-if="isLoading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-data-table
        :search="searchTimesheet"
        :loading="isLoading"
        loading-text="Loading Timesheet. Please wait...."
        :headers="headers"
        :items="timeSheetSummary"
      >
        <template v-slot:header.selected="{ header }">
          <v-checkbox @change="onSelectAll" color="primary" v-model="selectAll"></v-checkbox>
        </template>

        <template v-slot:item.name="{ item }">
          <button class="view-button" v-on:click="viewDetail(item)">{{item.name}}</button>
        </template>

        <template v-slot:item.selected="{ item }">
          <v-checkbox @change="onItemCheck" color="primary" v-model="item.selected"></v-checkbox>
        </template>

        <template v-slot:item.comment="{ item }">
          <input
            placeholder="Comment"
            v-model="item.comment"
            class="comment-box"
          />
          <!-- <TextField required="false" v-model="item.comment" class="comment-box" placeholder="Comment" /> -->
        </template>
      </v-data-table>
      <!-- <v-simple-table>
          <template v-slot:default>
              <thead>
                  <tr>
                      <th><v-checkbox v-model="selectAll" @change="onSelectAll" color="primary" /></th>
                      <th>Name</th>
                      <th>Regular</th>
                      <th>On Duty Overtime</th>
                      <th>Off Duty Overtime</th>
                      <th>Shift</th>
                      <th>Comment</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="timesheet in timeSheetSummary" :key="timesheet.employeeId">
                      <td><v-checkbox @change="onItemCheck" color="primary" v-model="timesheet.selected" /></td>
                      <td>{{timesheet.name}}</td>
                      <td>{{timesheet.regular}}</td>
                      <td>{{timesheet.onDutyOvertime}}</td>
                      <td>{{timesheet.offDutyOvertime}}</td>
                      <td>{{timesheet.shift}}</td>
                      <td>
                        <TextField required="false" v-model="timesheet.comment" placeholder="Comment" />
                      </td>
                  </tr>
              </tbody>
          </template>
      </v-simple-table> -->
    </div>
  </div>
  <Dialog ref="detailView" :title="'Timesheet Detail'" :width="800">
    <v-row>
      <div v-if="loadingDetail" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <span v-if="timesheetDetails !== null">
        <div class="row text-left">
          <div class="col-md-6">PrNumber</div>
          <div class="col-md-6"><b>{{timesheetDetails.prnumber}}</b></div>
          <div class="col-md-6">Personnel Name</div>
          <div class="col-md-6"><b>{{timesheetDetails.name}}</b></div>
          <div class="col-md-6">Salary Period</div>
          <div class="col-md-6"><b>{{timesheetDetails.salaryPeriod}}</b></div>
          <div class="col-md-6">Rotation</div>
          <div class="col-md-6"><b>{{timesheetDetails.rotation}}</b></div>
          <div class="col-md-6">Approval Status</div>
          <div class="col-md-6"><b>{{nextApprover}}</b></div>
          <!--<div class="col-md-6">Next Approval</div>
          <div class="col-md-6"><b>{{approversName}}</b></div>-->
        </div>
        <div v-if="timesheetDetails.timesheetItems.length > 0" class="row text-left timesheet-table">
          <h4>Timesheet Items</h4>
          <v-simple-table style="width: 100%; overflow-x: auto">
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="item in timesheetHeaders" :key="item.headerName">{{item.headerName}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="(item, index) in timesheetDetails.timesheetItems"
                      :key="item.timesheetItemDate"
                      v-bind:class="timesheetDetails.timesheetItems[index].type">
                    <span v-if="timesheetDetails.timesheetItems[index].show === true">
                      <b>{{timesheetDetails.timesheetItems[index].hoursWorked}}</b>
                    </span>
                    <span v-if="timesheetDetails.timesheetItems[index].show === false">
                      <b>{{timesheetDetails.timesheetItems[index].type}}</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </span>
    </v-row>
    <v-row>
      <v-card width="100%" elevation="0">
        <v-tabs v-model="tab">
          <v-tab href="#tab-2">Logs</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-2">
            <v-card class="pt-2" flat>
              <div v-if="loadingLogs" class="text-center">
                <v-progress-circular indeterminate
                  color="primary"></v-progress-circular>
              </div>
              <v-data-table v-else
                :headers='logHeaders'
                :items="logs">
                <template v-slot:item.activityDate="{ item }">
                  <span>{{ item.activityDate | formatDate }}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <template v-slot:footer>
      <Button
        :label="'Close'"
        :btnType="'Submit'"
        @onClick="closeDetailView"
      />
    </template>
  </Dialog>
  <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @commentPer="getComment" :mType="'forward'"  @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
    <Button
      :label="'Forward'"
      :btnType="'Submit'"
      @onClick="dialog = true"
      :disabled="DelegateEmail === null"
      :isLoading="forwarding"
      class="mr-4"
    />
  </PersonnelSearch>
  <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isProcessing" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
    <!--end timesheet calendar  -->
</v-app>
</template>

<script>
// import TextField from '@/components/ui/form/TextField.vue'
import Dialog from '@/components/ui/Dialog.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
// import { UPLOAD_TIMESHEET, SAVE_TIMESHEET } from '@/store/action-type'
import { timesheetService } from '@/services'
import moment from 'moment'
import { ROTATION, TimesheetShortCodes } from '@/utils/constants'

export default {
  components: {
    Dialog,
    PersonnelSearch,
    SelectField,
    Button,
    ConfirmationDialog
  },
  props: ['value'],
  data () {
    return {
      approversName: '',
      loadingLogs: false,
      personnelSearch: false,
      forwarding: false,
      DelegateEmail: '',
      DelegateName: '',      
      dialog: false,
      searchTimesheet: '',
      isLoading: false,
      isProcessing: false,
      selectAll: false,
      serverTimeSheets: [],
      rotations: [],
      selectedPersonnel: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      formData: {
        action: {}
      },
      search: '',
      requestType: [],
      salaryCycles: [],
      personnel: [],
      selected: [],
      timeSheets: [],
      searching: false,
      creating: false,
      headers: [
        {
          text: '',
          value: 'selected',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Regular',
          value: 'regular',
          sortable: false
        },
        {
          text: 'Rotation',
          value: 'rotation',
          sortable: false
        },
        {
          text: 'Location',
          value: 'location',
          sortable: false
        },
        {
          text: 'Salary Period',
          value: 'description',
          sortable: false
        },
        {
          text: 'Comment',
          value: 'comment',
          sortable: false
        }
      ],
      timeSheetSummary: [],
      timeSheetActions: [
        {
          name: 'Approve',
          id: 1
        },
        {
          name: 'Reject',
          id: 2
        },
        {
          name: 'Forward',
          id: 3
        }
      ],
      timesheetHeaders: [],
      timesheetDetails: null,
      loadingDetail: false,
      loadingApprovers: false,
      approvers: [],
      nextApprover: '',
      logs: [],
      tab: null,
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comments'
        },
        {
          text: 'Date',
          value: 'activityDate'
        }

      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name      
    },
    getComment (comment) {
      this.comment = comment
    },
    openConfirm () {
      const selectedItem = this.timeSheetSummary.filter(item => item.selected === true)
      if (selectedItem.length > 0) {
        if (this.formData.action.id === 3) {
          this.personnelSearch = true
        } else {
          this.dialog = true
        }
      }
    },
    closeDetailView () {
      this.$refs.detailView.closeModal()
    },
    viewDetail (item) {
      this.loadingDetail = true
      this.loadingApprovers = true
      this.loadingLogs = true
      this.timesheetDetails = null
      this.approvers = null
      this.logs = []

      // timesheetService.viewTimesheetApprovalDetail(item.timesheetId).then(result => {
      //   this.approvers = result.data
      //   const currentApprover = this.approvers.find(item => item.status.toString().includes('Current') === true)

      //   if (currentApprover !== undefined) {
      //     this.nextApprover = `Pending ${currentApprover.approver}`
      //   }
      // }).catch(() => {}).finally(() => {
      //   this.loadingApprovers = false
      // })

      timesheetService.viewTimesheetDetail(item.timesheetId).then(result => {
        this.generateTimesheet(result.data)
      }).catch(() => {}).finally(() => {
        this.loadingDetail = false
      })

      timesheetService.viewTimesheetLogs(item.timesheetId).then(result => {
        this.logs = result.data.logs
        const currentApprovers = result.data.currenApprover.find(item => item.status.toString().includes('Current') === true)
        this.nextApprover = `Pending ${currentApprovers.approver}`
        if (currentApprovers.approvers.length > 0) {
          currentApprovers.approvers.forEach(item => {
            this.approversName += `${item} `
          })
        }
      }).catch(() => {}).finally(() => {
        this.loadingLogs = false
      })
    },
    generateTimesheet (employeeTimesheet) {
      const salaryPeriod = employeeTimesheet.salaryPeriod
      let dateArr = []
      this.timesheetHeaders = []
      this.timeSheets = []
      const startDate = moment(salaryPeriod.startDate).format('MM-DD-YYYY')
      const endDate = moment(salaryPeriod.endDate).format('MM-DD-YYYY')

      var start = new Date(startDate)
      var end = new Date(endDate)

      while (start <= end) {
        dateArr.push({
          headerName: moment(start).format('ddd, MMM D'),
          date: moment(start).format('MM-DD-YYYY'),
          headerDay: moment(start).format('DD')
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      this.timesheetHeaders = dateArr
      // console.log(dateArr)
      this.createTimesheetDetailTable(employeeTimesheet)
    },
    createTimesheetDetailTable (timesheetDetails) {
      const { employee, timesheetItems, salaryPeriod } = timesheetDetails
      const rotationName = employee.rotation.name
      const newTimesheetItems = []

      try {
        this.timesheetHeaders.forEach(header => {
          const date = moment(header.date).format('MM-DD-YYYY')
          let timeSheetItem = timesheetItems.find(item => moment(item.timesheetItemDate).format('MM-DD-YYYY') === date)
          if (timeSheetItem !== undefined) {
            if (timeSheetItem.type === 'Holiday' || timeSheetItem.type === 'On Leave') {
              timeSheetItem = Object.assign({}, timeSheetItem, { show: false })
            } else {
              timeSheetItem = Object.assign({}, timeSheetItem, { show: true })
            }

            const typeShortCode = TimesheetShortCodes[timeSheetItem.type]
            timeSheetItem = Object.assign({}, timeSheetItem, { 'typeShortCode': typeShortCode })
            timeSheetItem.type = timeSheetItem.type === 'Holiday' ? 'holiday' : timeSheetItem.type
            newTimesheetItems.push(timeSheetItem)
          } else {
            newTimesheetItems.push({
              timesheetId: timesheetDetails.id,
              timesheetItemDate: date,
              hoursWorked: 0,
              isRegular: false,
              isOvertime: false,
              isShift: false,
              show: false,
              type: rotationName === ROTATION.OfficeRegular ? 'weekend' : '0',
              typeShortCode: rotationName === ROTATION.OfficeRegular ? 'W' : '0'
            })
          }
        })

        if (timesheetDetails !== undefined) {
          const newTimesheet = {
            prnumber: employee.prnumber,
            timesheetId: timesheetDetails.id,
            name: `${employee.lastName} ${employee.firstName} ${employee.middleName}`,
            employeeId: employee.id,
            rotation: rotationName,
            salaryPeriod: salaryPeriod.description,
            timesheetItems: newTimesheetItems
          }

          this.timesheetDetails = newTimesheet
          this.$refs.detailView.openModal()
        }
      } catch (error) {
        console.error(error)
      }
    },
    onSave () {
      this.isProcessing = true
      const approval = []

      this.timeSheetSummary.forEach(item => {
        if (item.selected) {
          // if (this.comment === '') {
          if (this.formData.action.id === 2 && item.comment === '') {
            this.showAlertMessage('Enter comment for timesheet(s)', 'error')
            this.isProcessing = false
            this.dialog = false
            return
          }
          approval.push({
            id: item.timesheetId,
            status: this.formData.action.id,
            comments: item.comment
          })
        }
      })

      if (approval.length > 0) {
        if (this.formData.action.id === 3) {
          const payload = []
          approval.forEach(item => {
            payload.push({
              ItemId: item.id,              
              DelegateName: this.DelegateName,
              DelegateEmail: this.DelegateEmail
            })
          })

          timesheetService.forwardTimesheet(payload).then(() => {
            this.showAlertMessage('Operation perfomed successfully', 'success')
            this.fetchTimesheet()
          }).catch(() => {
            this.showAlertMessage('Something went wrong, please try again later', 'error')
          }).finally(() => {
            this.isProcessing = false
            this.dialog = false
            this.personnelSearch = false
          })
        } else {
          timesheetService.approveTimesheet(approval).then(() => {
            this.timeSheetSummary = []
            this.showAlertMessage('Operation perfomed successfully', 'success')
            this.fetchTimesheet()
          }).catch(() => {
            this.showAlertMessage('Something went wrong, please try again later', 'error')
          }).finally(() => {
            this.isProcessing = false
            this.dialog = false
          })
        }
      }
    },
    selectedCycle (salaryCycle) {
      // timesheetService.pendingTimesheet().then(result => {
      //   this.serverTimeSheets = result.data.items
      //   this.createTimesheetTable()
      // }).catch(error => {
      //   console.log(error)
      // })
    },
    selectedRotation (rotation) {
      // timesheetService.pendingTimesheet().then(result => {
      //   this.serverTimeSheets = result.data.items
      //   this.createTimesheetTable()
      // }).catch(error => {
      //   console.log(error)
      // })
    },
    createTimesheetTable () {
      if (this.serverTimeSheets.length > 0) {
        const summary = []
        this.serverTimeSheets.forEach(timesheet => {
          const newSummary = {
            description: timesheet.salaryPeriodDescription,
            timesheetId: timesheet.timesheetId,
            name: timesheet.employeeName,
            employeeId: timesheet.employeeId,
            salaryPeriodId: timesheet.salaryPeriodId,
            regular: timesheet.regular,
            onDutyOvertime: timesheet.onDutyOvertime,
            offDutyOvertime: timesheet.offDutyOvertime,
            location: timesheet.location,
            rotation: timesheet.rotation,
            shift: timesheet.shift,
            status: 'Pending',
            action: 0,
            comment: '',
            selected: false
          }

          summary.push(newSummary)
        })

        this.timeSheetSummary = summary
      }
    },
    onSelectAll (value) {
      const timesheetCount = this.timeSheetSummary.length - 1
      for (let index = 0; index <= timesheetCount; index++) {
        this.timeSheetSummary[index].selected = value
      }
    },
    onItemCheck (value) {
      this.selectAll = false
    },
    fetchTimesheet () {
      this.isLoading = true
      timesheetService.pendingTimesheet().then(result => {
        if (result.data.length > 0) {
          this.serverTimeSheets = result.data
          this.createTimesheetTable()
        }

        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    }
  },
  mounted () {
    this.fetchTimesheet()
  }
}
</script>

<style scoped>
  .timesheet-table {
    width: 750px;
  }
  .comment-box {
    /* margin-top: 1rem; */
    border: 1px solid grey;
    height: 30px;
    padding-left: 5px;
    width: 190px;
  }
  .view-button {
    text-align: left;
    border: none;
    color: #0066b2;
  }
  .view-button:focus {
    border: none;
  }
  .view-button:hover {
    text-decoration: underline;
  }
  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-end;
  }

  .hour-input {
      border: 1px rgb(156, 154, 154) solid;
      width: 50px;
      padding: 1px;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
